import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/var/www/reserv/argos/node_modules/@mantine/core/esm/components/Container/Container.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Box"] */ "/var/www/reserv/argos/node_modules/@mantine/core/esm/core/Box/Box.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Body"] */ "/var/www/reserv/argos/src/shared/ui/body/Body.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Breadcrumb"] */ "/var/www/reserv/argos/src/shared/ui/breadcrumb/Breadcrumb.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CalendarLink"] */ "/var/www/reserv/argos/src/shared/ui/calendar-link/CalendarLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomLink"] */ "/var/www/reserv/argos/src/shared/ui/custom-link/CustomLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DetailsLink"] */ "/var/www/reserv/argos/src/shared/ui/details-link/DetailsLink.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Empty"] */ "/var/www/reserv/argos/src/shared/ui/empty/Empty.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LinkBox"] */ "/var/www/reserv/argos/src/shared/ui/link-box/LinkBox.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Masonry"] */ "/var/www/reserv/argos/src/shared/ui/masonry/Masonry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotFound"] */ "/var/www/reserv/argos/src/shared/ui/not-found/NotFound.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ScrollAnimation"] */ "/var/www/reserv/argos/src/shared/ui/scroll-animation/ScrollAnimation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SliderArrow"] */ "/var/www/reserv/argos/src/shared/ui/slider-arrow/SliderArrow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocialMediaIcon"] */ "/var/www/reserv/argos/src/shared/ui/social-media-icon/SocialMediaIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SuspenseLoader"] */ "/var/www/reserv/argos/src/shared/ui/suspense-loader/SuspenseLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Title"] */ "/var/www/reserv/argos/src/shared/ui/title/Title.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Views"] */ "/var/www/reserv/argos/src/shared/ui/views/Views.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ZoomImage"] */ "/var/www/reserv/argos/src/shared/ui/zoom-image/ZoomImage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accessibility"] */ "/var/www/reserv/argos/src/widgets/accessibility/Accessibility.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackToTop"] */ "/var/www/reserv/argos/src/widgets/back-to-top/BackToTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Captcha"] */ "/var/www/reserv/argos/src/widgets/captcha/Captcha.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Content"] */ "/var/www/reserv/argos/src/widgets/content/Content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Employee"] */ "/var/www/reserv/argos/src/widgets/employee/Employee.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfoContent"] */ "/var/www/reserv/argos/src/widgets/info-content/InfoContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageSwitcher"] */ "/var/www/reserv/argos/src/widgets/language-switcher/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Messenger"] */ "/var/www/reserv/argos/src/widgets/messenger/Messenger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsCard"] */ "/var/www/reserv/argos/src/widgets/news-card/NewsCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OnlineConsultant"] */ "/var/www/reserv/argos/src/widgets/online-consultant/OnlineConsultant.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaginationWidget"] */ "/var/www/reserv/argos/src/widgets/pagination/Pagination.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Poll"] */ "/var/www/reserv/argos/src/widgets/poll/Poll.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressBar"] */ "/var/www/reserv/argos/src/widgets/progress-bar/ProgressBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PushNotification"] */ "/var/www/reserv/argos/src/widgets/push-notification/PushNotification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RightMenu"] */ "/var/www/reserv/argos/src/widgets/right-menu/RightMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Share"] */ "/var/www/reserv/argos/src/widgets/share/Share.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkipToMainContent"] */ "/var/www/reserv/argos/src/widgets/skip-to-main-content/SkipToMainContent.tsx");
